.footer {
  overflow: hidden;
  background: linear-gradient(133deg, #fffbf9 0%, #ffedee 100%);

  .logo {
    width: 149px;
  }

  .footerTitle {
    width: 100%;
    display: flex;
    padding: 60px 60px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(133deg, #fffbf9 0%, #ffedee 100%);

    .name {
      width: 787px;
      height: 134px;
      font-size: 48px;
      font-weight: bold;
      color: #0e1527;
      // line-height: 72Px;
    }

    .button {
      margin-top: 86px;
      width: 297px;
      height: 70px;
      background: #ff3e5f;
      box-shadow: 0px 14px 29px -5px rgba(255, 62, 95, 0.4);
      border-radius: 35px;
      backdrop-filter: blur(4.5px);
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      line-height: 70px;
      cursor: pointer;
    }

    .button:hover {
      background: #ff667a;
    }

    .button:active {
      background: #d92a4d;
    }
  }

  .footerBottom {
    width: 100%;
    background-color: #141735;
    padding: 80px 0 0;
    padding-bottom: 48px;

    .footerBottomContent {
      margin: 0 auto;


      &>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // height: 260Px;
        // justify-content: space-between;

        .head {
          font-size: 20px;
          font-family: PopinsSemi, Poppins;
          font-weight: 600;
          color: #ffffff;
          line-height: 30px;
        }

        .child {
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          line-height: 25px;
          margin-top: 40px;
        }
      }
    }

    .info {
      width: 240px;

      .logo {
        width: 147.68px;
        color: #fff;
      }

      .slogan {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        // margin-left: 51Px;
        font-size: 20px;
        color: #fff;
        // margin-left: -58Px;
      }

      .detail {
        // margin-left: 159Px;
        color: rgba(255, 255, 255, 0.65);
        display: flex;
        justify-content: space-between;

        .item {
          padding: 0 20px;
          border-right: 1px solid rgba(255, 255, 255, 0.65);
          font-weight: 400;
          font-size: 14px;
        }

        &>.item:first-child {
          padding-left: 0;
        }

        &>.item:last-child {
          padding-right: 0;
          border-right-width: 0;
        }
      }
    }

    .bottom {
      width: 1181px;
      margin: 0 auto;
      padding-top: 32px;
      padding-bottom: 24px;
      border-top: 1px solid rgba(255, 255, 255, 0.35);

      text-align: center;
      font-size: 12px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.35);
      line-height: 16px;
    }
  }

  .hrefDiv {
    flex-direction: row !important;
    flex: 1;
    justify-content: space-around;
    padding: 0 6%;
  }
}



@function selfAdapt($Px) {
  @return 100vw * ($Px / 375);
}

@media screen and (max-width: 1024px) {
  .footer {
    background: #ffffff;

    .footerBottom {
      .footerBottomContent {
        padding: 20px;
      }
    }
  }
}


.mobileFooter {
  padding-bottom: 40px;

  .info {
    font-size: 20px;
  }

  .hrefDiv {
    display: flex;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between;
    font-size: 12px;
    padding: 0;

    .href {
      //width: 50%;
    }
  }
}