//这个函数的作用是对于一些需要动态从某一个分辨率屏幕到另一个分辨率的变化
//$max (需要变化的最大值), $min(需要变化的最小值),
//$maxScreen(需要变化的最大值时屏幕的大小), $minscreen(需要变化的最大值时屏幕的大小), $diff(max-min)
@function selfAdapt1($max, $min, $diff) {
  // @return $max - (($maxScreen - 100vw) / (($maxScreen - $minScreen) / ($diff)));
  @return #{"calc(#{$max} - ((1280Px - 100vw) / (256 / #{$diff})))"};
}

.nav {
  // max-width: 1840Px;
  // padding: 0 40px;
  height: 70px;
  position: fixed;
  // background: linear-gradient(132deg, #D4F4FF 0%, #FFF5D9 62%, #FFCDBB 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // overflow: hidden;
  width: 100%;
  z-index: 50;
  transition: all 200ms ease-in-out;

  .logo {
    width: 149px;
  }

  a {
    // margin: 0 16Px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #191919;
  }

  .left {
    display: flex;
    align-items: center;

    .menus {
      margin-left: 24px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        color: #0e1527;
        // padding: 0 36px;
        padding-left: 36px;

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }

      //   a {
      //     display: flex;
      //     margin: 0 16px;
      //     cursor: pointer;
      //     font-size: 14px;
      //     font-family: PingFangSC-Regular, PingFang SC;
      //     font-weight: 400;
      //     color: #191919;
      //   }

      .menusItem {
        position: relative;
        :hover {
          .iconArrow {
            transform: rotate(180deg);
          }
        }
        .iconArrow {
          transition: all .3s ease-in-out;
        }
        .menusDropdown {
          display: none;
          position: absolute;
          bottom: 0;
          transform: translateY(100%);
          background: #ffffff;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: 1px solid #f0f0f0;
          padding: 10px 8px;

          .dropdownList {
            .dropdownListItem {
              margin: 0 !important;
              width: 180px;
              height: 40px;
              padding-left: 23px;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              line-height: 40px;

              &:hover {
                background: #f6f6f6;
              }
            }
          }
        }

        &:hover {
          .menusDropdown {
            display: block !important;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .login {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #191919;
      cursor: pointer;
      margin-right: 15px;

      width: 68px;
      height: 26px;
      border-radius: 13px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      text-align: center;
      line-height: 26px;
    }

    .start {
      display: block;
      width: 110px;
      height: 36px;
      background: #ff3e5f;
      box-shadow: 0px 10px 14px -4px rgba(246, 69, 110, 0.34);
      border-radius: 18px;
      margin-left: 24px;
      margin-right: 24px;
      text-align: center;
      line-height: 36px;
      vertical-align: middle;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }

    .start:hover {
      background: #ff667a;
    }

    .start:active {
      background: #d92a4d;
    }

    .mobileOpen {
      display: none;
      cursor: pointer;
    }
  }
}

.mobileNav {
  display: none;
  overflow: auto;
  position: fixed;
  background-color: #fff;
  width: 85vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 51;

  .mobileTop {
    padding: 10px 16px;
    height: 70px;
    // margin-top: 44px;
    // margin-bottom: 21px;
    // padding: 24px 24px 24px 30px;
  }

  .mobileClose {
    float: right;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .mobileMenus {
    position: relative;

    .mobileLink {
      padding: 12px 0 12px 30px;

      a {
        color: #0e1527;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        // padding: 0 16px;
      }

      .sub {
        // margin-left: 20Px;

        .mobileSubLink {
          padding: 19px 0 19px 30px;
        }

        :first-child {
          padding-top: 38px;
        }
      }
    }

    .chooseLang {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;

      svg {
        width: 24px;
        height: 24px;
        margin: 0 24px;
      }
    }

    .subMobileLink {
      display: none;
    }
  }

  .mobileStart {
    display: block;
    position: absolute;
    // left: 50%;
    margin-left: calc((100% - 260px) / 2);
    text-align: center;
    width: 260px;
    height: 56px;
    background: #ff3e5f;
    box-shadow: 0px 10px 14px -4px rgba(246, 69, 110, 0.34);
    border-radius: 18px;
    text-align: center;
    line-height: 56px;
    vertical-align: middle;
    font-size: 14aPx;
    color: #fff;
    cursor: pointer;
    bottom: 35px;
  }

  .mobileStart:hover {
    background: #ff667a;
  }

  .mobileStart:active {
    background: #d92a4d;
  }

  .mobileMenuList {
    position: relative;
    background: #fff;

    .menuItem {
      overflow: hidden;
      background: #fff;

      .menuHeader {
        height: 48px;
        padding: 14px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        background: #fff;
      }

      .menuHeaderLeft {
        display: flex;
        align-items: center;

        .iconfont {
          color: #0e1527;
        }

        a {
          font-size: 16px;
          font-weight: 600;
          color: #0e1527;
          line-height: 22px;
          margin-left: 16px;
        }
      }

      .menuHeaderRight {
        i {
          font-size: 20px;
        }
      }

      .menuChild {
        transform: translateY(0);
        opacity: 1;
        height: 100%;
        overflow: hidden;
        transition: all 200ms linear;
      }

      .menuChildItem {
        height: 48px;
        width: 100%;
        padding: 13px 0;
        padding-left: 48px;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        color: #0e1527;
        line-height: 22px;
        z-index: -1;
      }

      .menuChildHidden {
        transform: translateY(-40%);
        opacity: 0;
        height: 0;
      }
    }
  }

  .userInfo {
    border-top: 1px solid #f0f0f0;
    margin: 43px 0;
    display: flex;
    padding: 48px 16px 0;

    .userInfo_name {
      font-size: 14px;
      font-weight: 600;
      color: #0e1527;
      line-height: 20px;
      margin-bottom: 1px;
    }

    .userInfo_tip {
      font-size: 12px;
      font-weight: 400;
      color: #708096;
      line-height: 17px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1310px) {
  .nav {
    .left {
      .menus {
        a {
          padding-left: 12px;
        }
      }
    }
  }
}

// @media screen and (max-width: 1130px) {
//   .nav {
//     .right {
//       .login {
//         margin-right: 15px;

//         width: 62px;
//         height: 26px;
//         border-radius: 13px;
//         opacity: 0.69;
//         border: 1px solid #979797;

//         a {
//           font-size: 12px;
//           font-family: PingFangSC-Semibold, PingFang SC;
//           font-weight: 600;
//           color: #191919;
//         }

//         @apply flex items-center justify-center;

//       }

//       .start {
//         display: none;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1180px) {
//   .nav {
//     .left {
//       .menus {
//         display: inline-flex;
//         align-items: center;

//         // margin-left: 44Px;
//         a {
//           margin: 0 16px;
//           cursor: pointer;
//           font-size: 14px;
//           font-family: PingFangSC-Regular, PingFang SC;
//           font-weight: 400;
//           color: #191919;
//           transition: all 100ms ease-in-out;
//           display: inline-flex;
//           align-items: center;
//         }

//         svg {
//           display: inline;
//         }

//         path {
//           fill: #191919;
//         }

//         a:hover {
//           transition: all 100ms ease-in-out;
//           // text-decoration: underline;
//           color: #ff3e5f;

//           path {
//             fill: #ff3e5f;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1180Px) and (min-width: 901Px) {
//   .nav {
//     padding: 0 30Px;
//     // min-width: 850Px;

//     .left {
//       .menus {
//         margin-left: 24Px;

//         a {
//           margin: 0 12Px;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 1024px) and (min-width: 375px) {
  .nav {
    padding: 0 24px;

    .left {
      .menus {
        // display: none;
      }
    }

    .right {
      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) and (min-width: 375px) {
  .nav {
    padding: 0 24px;

    .left {
      .menus {
        display: none;
      }
    }

    .right {
      // .login,
      .start {
        display: none !important;
      }

      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .nav {
    width: 100%;
    padding: 0 20px;

    .left {
      .menus {
        display: none;
      }
    }

    .right {
      // .login,
      .start {
        display: none !important;
      }

      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
