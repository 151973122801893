.selects {
    margin-top: -21px;
    position: absolute;
    font-size: 14px;
    z-index: 3;
    color: #595959;
    /*设置绝对定位，宽度为内容宽度*/
    left: -999em;
    /*设置隐藏*/
    padding: 8px;
    padding-bottom: 30px;
    width: 266px;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
    display: none;

    /*离它的父元素的边界为0，所以可以位于父元素li的正下方*/
    li {
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        transition: all 200ms ease-in-out;

        a {
            display: block;
            /*菜单项宽度未知，设置inline－block宽度为内容宽度*/
            // border-top: 1px solid #ddd;
            // border-left: 1px solid #ddd;
            // border-right: 1px solid #ddd;
            // height: 30px;
            // padding: 0 10px;
            // line-height: 30px;
        }
    }

    li:hover {
        transition: all 200ms ease-in-out;
        background: #f6f6f6;
    }
}

.userNav,
.userContainer {
    cursor: pointer;
    display: block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
}

.userNav:hover {
    // .downArrow::after {
    //     transform: rotate((225deg));
    //     margin-top: -4px;
    // }
}

.userContainer:hover .selects {
    left: -150px;
    display: block;
    margin-top: -10px;
    // right: 20px
}

@media screen and (max-width:1023px) {
    .userNav {
        display: none;
    }
}